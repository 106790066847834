// Libraries
import * as React from 'react'
import { navigate } from 'gatsby'

// Services
import AuthService from '../../services/auth'

// Components
import Layout from '../../components/layout'
import DuoSection from '../../components/duoSection'
import Seo from '../../components/seo'
import InputFactory from '../../components/inputFactory'
import Button from '../../components/button'
import { Link } from 'gatsby'

class AdminLoginPage extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
	}

	render() {
		const { inputValues, inputErrors, isSubmitting } = this.state

		return (
			<Layout className="page--login nav-blue-half page--login-admin">
				<Seo title="Admin Login" />
				<DuoSection login>
					<div>
						<div className="content-wrap">
							<h1 className="color--endeavour">Admin Login</h1>
							<form onSubmit={this.handleSubmit}>
								<InputFactory
									type="email"
									name="email"
									placeholder="Email"
									value={inputValues['email']}
									error={inputErrors['email']}
									onChange={this.handleInputChange}
								/>
								<InputFactory
									type="password"
									name="password"
									placeholder="Password"
									value={inputValues['password']}
									error={inputErrors['password']}
									onChange={this.handleInputChange}
								/>
								<p>
									<Link to="/forgotten-password" className="link--text color--endeavour">
										Forgotten Password?
									</Link>
								</p>
								<InputFactory
									type="checkbox"
									name="remember_me"
									label="Remember me"
									value={inputValues['remember_me']}
									error={inputErrors['remember_me']}
									onChange={this.handleInputChange}
								/>
								<Button type="submit" isLoading={isSubmitting} colorEndeavour xsBlock>
									Login
								</Button>
							</form>
						</div>
					</div>
					<div></div>
				</DuoSection>
			</Layout>
		)
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const { inputValues } = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			} else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			} else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async event => {
		event.preventDefault()
		this.setState({
			isSubmitting: true,
		})

		const authService = new AuthService()
		const { inputValues } = this.state
		await authService
			.login(inputValues['email'], inputValues['password'], inputValues['remember_me'], true)
			.then(response => {
				if (response.success) {
					navigate('/admin/users')
				} else {
					this.setState({
						inputErrors: response.errors,
						isSubmitting: false,
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminLoginPage
